import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FullWidthContainer from '../components/fullWidthContainer';
import Header from './header';
import Footer from './footer';
import SEO from './seo';

const Layout = ({
  pageTitle,
  children,
  contentInsideBackground,
  logoImage,
  companyLogoAlt,
  logoClassName,
}) => {
  const data = useStaticQuery(graphql`
    query navbarLayoutQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      prismicCompanyLogo {
        data {
          company_name
        }
      }
      allPrismicSocialMedia {
        nodes {
          data {
            social_media_type
            social_media_account_link {
              url
            }
          }
        }
      }
      prismicNavbar {
        data {
          body {
            ... on PrismicNavbarDataBodyMenu {
              id
              primary {
                is_featured
                link_label {
                  raw
                }
                link_url {
                  url
                }
                link_url_anchor
              }
              slice_type
              items {
                link_url {
                  url
                }
                link_label {
                  raw
                }
                link_url_anchor
                description {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `);

  const site = data.site;

  const menuData = data.prismicNavbar.data.body;

  const companyName = data.prismicCompanyLogo.data.company_name;

  let content = (
    <div
      className='mx-auto md:flex md:h-full md:flex-col md:justify-between mega:h-auto'
      role='document'
    >
      <FullWidthContainer role='article'>
        <Header
          menuItems={menuData}
          logoImage={logoImage}
          logoAlt={companyLogoAlt}
          logoClassName={logoClassName}
          companyName={companyName}
        />
      </FullWidthContainer>
      {contentInsideBackground ? contentInsideBackground() : null}
    </div>
  );

  return (
    <div className='mx-auto w-full md:h-auto md:w-11/12'>
      <SEO site={site} title={pageTitle} />
      <div className='ie11 bg-primary px-4 py-3 text-center text-white'>
        <p>
          Internet Explorer is no longer supported by Microsoft. Our website is
          best viewed in a modern browser such as Google Chrome or Microsoft
          Edge. Thank you!
        </p>
      </div>
      {content}
      {children}
      {/* <PrivacyPolicy /> */}
      <Footer />
    </div>
  );
};

export default Layout;
