import React from 'react';
import PropTypes from 'prop-types';
import NewNavbar from './navbar/newNavbar';

//update the menu items prismic structure and refactor here for new navbar support

const Header = ({
  logoImage,
  logoAlt,
  menuItems,
  logoClassName,
  companyName,
}) => {
  return (
    <header>
      <div className='mx-auto flex flex-col justify-center pb-4 pt-4 md:pt-0'>
        <NewNavbar showCompanyName bgColor='bg-white' menuItems={menuItems} />
      </div>
    </header>
  );
};

Header.propTypes = {
  logoFluid: PropTypes.object,
  menuItems: PropTypes.array,
};

export default Header;
