import React from 'react';

const JustifiedContainer = ({ children, className }) => {
  return (
    <div className={`leading-loose lg:text-justify ${className}`}>
      {children}
    </div>
  );
};

export default JustifiedContainer;
