import * as React from 'react';
import { graphql } from 'gatsby';
import FullWidthContainer from '../components/fullWidthContainer';
import JustifiedContainer from '../components/justifiedContainer';
import Layout from '../components/layout';
import { SliceZone } from '../sliceZone';
import Image from '../components/image';

const PageTemplate = ({ data }) => {
  if (!data) {
    return null;
  }

  const doc = data.prismicPage;

  const companyInfoData = data.prismicCompanyLogo.data;

  const backgroundImage = doc.data.body[0].primary.image.gatsbyImageData;

  const companyLogoImage = companyInfoData.logo.gatsbyImageData;
  const companyLogoAlt = companyInfoData.logo.alt;

  return (
    <Layout
      pageTitle={doc.data.page_title}
      logoImage={companyLogoImage}
      companyLogoAlt={companyLogoAlt}
      logoClassName='max-w-xxxxs 2xl:max-w-xxxs 3xl:max-w-xxs'
    >
      <div className='flex flex-col items-center'>
        <FullWidthContainer className='flex flex-col'>
          <JustifiedContainer className='mb-8 text-center md:text-base'>
            <Image
              image={backgroundImage}
              alt='background image'
              className='h-96 rounded shadow md:mt-2'
            />
            <h1 className='mt-12 text-center text-4xl font-bold text-gray-800 md:text-5xl'>
              {doc.data.page_title}
            </h1>
          </JustifiedContainer>
        </FullWidthContainer>
        <SliceZone slices={doc.data.body} />
      </div>
    </Layout>
  );
};

export const query = graphql`
          query PageQuery($id: String) {
            prismicCompanyLogo {
            data {
            company_name
        logo {
            gatsbyImageData
          alt
        }
      }
    }
          prismicPage(id: {eq: $id }) {
            data {
            page_title
        body {
            ...on PrismicPageDataBodyGiftupGiftCards {
            slice_type
            primary {
            title {
            raw
          }
          giftup_script_url {
            url
          }
          giftup_data_site_id
          description {
            raw
          }
            }
          }
          ... on PrismicPageDataBodyBlogRoll {
            slice_type
            primary {
            title {
            raw
          }
          description {
            raw
          }
          empty_feed_image {
            alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicPageDataBodyEventSchedule {
            slice_type
            primary {
            title {
            raw
          }
          link {
            url
          }
          description {
            raw
          }
            }
          }
          ... on PrismicPageDataBodyConstantContactSignupForm {
            slice_type
            primary {
            title {
            raw
          }
          form_title
          form_description
          constant_contact_script_variable
          constant_contact_script_url {
            url
          }
            }
          }
          ... on PrismicPageDataBodyAlternateGrid {
            primary {
            description {
            raw
          }
          image_side
          title {
            raw
          }
          optional_image {
            alt
                gatsbyImageData
              }
            }
          slice_type
          items {
            title {
            raw
          }
          optional_icon {
            alt
                gatsbyImageData
              }
          description {
            raw
          }
          link {
            url
          }
            }
          }
          ... on PrismicPageDataBodyLinksCollection {
            slice_type
            primary {
            title {
            raw
          }
            }
          items {
            link_label
              optional_image {
            gatsbyImageData
          }
          link {
            url
          }
            }
          }
          ... on PrismicPageDataBodySectionTitle {
            primary {
            title {
            raw
          }
            }
          slice_type
          }
          ... on PrismicPageDataBodyFormSection {
            slice_type
            primary {
            form_submit_text
              title {
            raw
          }
          form_post_url {
            url
          }
            }
          items {
            form_field
              is_required
          form_field_type
            }
          }
          ... on PrismicPageDataBodyBackgroundHeroImage {
            primary {
            image {
            alt
                gatsbyImageData
              }
            }
          slice_type
          }
          ... on PrismicPageDataBodyFaqSection {
            items {
            title {
            raw
          }
          text {
            raw
          }
            }
          slice_type
          primary {
            description {
            raw
          }
          title {
            raw
          }
            }
          }
          ... on PrismicPageDataBodyCallToAction {
            slice_type
            primary {
            call_to_action_label
              call_to_action_link {
            url
          }
            }
          }
          ... on PrismicPageDataBodySectionFeaturingLink {
            slice_type
            primary {
            title {
            raw
          }
          link {
            url
          }
          image {
            alt
                gatsbyImageData
              }
          embed_in_page
          use_primary_color_background
          description {
            raw
          }
            }
          }
        }
      }
          uid
          id
    }
  }
          `;

export default PageTemplate;
